<template>
  <el-form
    :model="formData"
    :rules="rules"
    ref="formRef"
    label-width="140px"
    label-suffix="："
  >
    <el-form-item label="场景类型" prop="sellPosition">
      <el-select
        :disabled="disabled"
        filterable
        clearable
        v-model="formData.sellPosition"
        placeholder="请选择场景类型"
      >
        <el-option
          v-for="item in sceneTypeList"
          :key="item.key"
          :label="item.value"
          :value="item.key"
        ></el-option>
      </el-select>
    </el-form-item>
    <!-- <el-form-item label="等级" prop="level">
      <el-select
        :disabled="disabled"
        filterable
        clearable
        v-model="formData.level"
        placeholder="请选择等级"
        v-if="formData.sceneType == 2"
      >
        <el-option
          v-for="item in gradeTypeList"
          :key="item.key"
          :label="item.value"
          :value="item.key"
        ></el-option>
      </el-select>
      <span v-else>全部</span>
    </el-form-item> -->
    <!-- <el-form-item label="推荐版位" prop="sellPosition">
      <el-select
        :disabled="disabled"
        filterable
        clearable
        v-model="formData.sellPosition"
        placeholder="请选择推荐版位"
      >
        <template v-for="item in sellPositionList">
          <el-option
            v-if="item.propKey == formData.sceneType"
            :key="item.key"
            :label="item.value"
            :value="item.key"
          ></el-option>
        </template>
      </el-select>
    </el-form-item> -->
    <el-form-item label="国家" prop="countriesIso2">
      <el-select
        filterable
        clearable
        multiple
        collapse-tags
        v-model="formData.countriesIso2"
        placeholder="请选择国家"
      >
        <el-option
          v-for="item in areaList"
          :key="item.iso2"
          :label="item.name"
          :value="item.iso2"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="出价方式" prop="settlementType">
      <el-select
        :disabled="disabled"
        filterable
        clearable
        v-model="formData.settlementType"
        placeholder="请选择出价方式"
      >
        <!-- 目前只有cpd -->
        <el-option label="CPD" :value="1"></el-option>
        <!-- <template v-for="item in settlementTypeList">
          <el-option
            v-if="[3].includes(item.key)"
            :key="item.key"
            :label="item.value"
            :value="item.key"
          ></el-option>
        </template> -->
      </el-select>
    </el-form-item>
    <el-form-item label="金额阈值" prop="amountThreshold">
      <el-input
        v-model="formData.amountThreshold"
        placeholder="请输入上涨金额阈值"
      ></el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="handleSubmit()">立即提交</el-button>
      <el-button @click="handleCancel()">返回</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import {
  sceneTypeList,
  sellPositionList,
  settlementTypeList,
  gradeTypeList,
} from "@/common/constant/constant.js";
export default {
  props: {
    detailData: {
      type: Object,
      default() {
        return {};
      },
    },
    areaList: {
      type: Array,
      default() {
        return [];
      },
    },
    operationType: { default: "" },
  },
  data() {
    const checkNum = function (rule, value, callback) {
      if (value && isNaN(value)) {
        callback(Error("请输入数字"));
      } else {
        callback();
      }
    };
    return {
      sceneTypeList,
      sellPositionList,
      settlementTypeList,
      gradeTypeList,
      formData: {},
      rules: {
        sellPosition: [{ required: true, message: "请选择场景类型" }],
        countriesIso2: [{ required: true, message: "请选择国家" }],
        // level: [{ required: true, message: "请选择等级" }],
        // sellPosition: [{ required: true, message: "请选择推荐版位" }],
        settlementType: [{ required: true, message: "请选择出价方式" }],
        amountThreshold: [
          { required: true, message: "请输入上涨金额阈值" },
          { validator: checkNum },
        ],
      },
    };
  },
  computed: {
    disabled() {
      return this.operationType === "edit";
    },
  },
  methods: {
    handleSubmit() {
      // if (this.formData.sceneType == 1 || !this.formData.sceneType) {
      //   this.formData.level = 0;
      // }
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          const { amountThreshold, ...rest } = this.formData;
          const data = { ...rest, amountThreshold: Number(amountThreshold) };
          data.countriesIso2 = data.countriesIso2.join();
          this.$emit("submit", data);
        }
      });
    },
    handleCancel() {
      this.$emit("cancel");
    },
    // 其他
    initData(data) {
      this.formData = {
        amountThreshold: "",
        // sceneType: "",
        // level: "",
        sellPosition: "",
        // settlementType: "",
        ...data,
        countriesIso2: [],
      };
      if (data.counList) {
        let arr = data.counList.map((item) => {
          return item.iso2;
        });
        this.formData.countriesIso2 = arr;
      }
    },
  },
  watch: {
    detailData: {
      immediate: true,
      handler(data) {
        this.initData(data);
        if (this.operationType === "create") {
          this.$refs.formRef && this.$refs.formRef.resetFields();
        }
      },
    },
  },
};
</script>
<style scoped lang="scss">
.el-input,
.el-select {
  width: 500px;
}
</style>
