<template>
  <div class="list-area">
    <el-row>
      <el-button
        type="primary"
        v-has="'add'"
        icon="el-icon-plus"
        @click="handleOperate('create')"
        >添加</el-button
      >
      <ExportTable
        v-has="'download'"
        class="ml10"
        @download="getDownloadList"
      ></ExportTable>
    </el-row>
    <el-row :gutter="6">
      <el-col :span="4">
        <el-select
          style="width: 100%"
          filterable
          clearable
          v-model="searchData.sellPosition"
          placeholder="请选择场景类型"
          @change="handleChangeSceneType"
        >
          <el-option
            v-for="item in sceneTypeList"
            :key="item.key"
            :label="item.value"
            :value="item.key"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select
          style="width: 100%"
          filterable
          clearable
          v-model="searchData.iso2"
          placeholder="请选择国家"
        >
          <template>
            <el-option
              v-for="item in areaList"
              :key="item.iso2"
              :label="item.name"
              :value="item.iso2"
            ></el-option>
          </template>
        </el-select>
        <!-- <el-select
          style="width: 100%"
          filterable
          clearable
          v-model="searchData.sellPosition"
          placeholder="请选择推荐版位"
        >
          <template v-for="item in sellPositionList">
            <el-option
              v-if="item.propKey == searchData.sceneType"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            ></el-option>
          </template>
        </el-select> -->
      </el-col>
      <el-col :span="4"
        ><el-button type="primary" icon="el-icon-search" @click="handleSearch()"
          >查询</el-button
        ></el-col
      >
    </el-row>
    <PageTable
      :columns="columns"
      :tableData="tableData"
      @page-change="handleChangePage"
    >
      <template slot="sellPosition" slot-scope="{ row }">
        <span>{{ row.sellPosition | typeDesc(sceneTypeList) }}</span>
      </template>
      <!-- <template slot="level" slot-scope="{ row }">
        <span v-if="row.sceneType == 2">{{
          row.level | typeDesc(gradeTypeList)
        }}</span>
        <span v-else>全部</span>
      </template> -->
      <!-- <template slot="sellPosition" slot-scope="{ row }">
        <span>{{ row.sellPosition | typeDesc(sellPositionList) }}</span>
      </template> -->
      <template slot="settlementType" slot-scope="{ row }">
        <!-- <span>{{ row.settlementType | typeDesc(settlementTypeList) }}</span> -->
        <span>CPD</span>
      </template>
      <template slot="counList" slot-scope="{ row }">
        <span>{{ countChange(row.counList) }}</span>
      </template>
      <template slot-scope="{ row }" slot="status">
        <el-tag :type="row.status | tagType(statusList)">
          {{ row.status | typeDesc(statusList) }}
        </el-tag>
      </template>
      <el-table-column label="操作" width="180px" align="center">
        <template slot-scope="{ row }">
          <el-button
            type="text"
            v-has="'edit'"
            @click="handleOperate('edit', row)"
            >编辑</el-button
          >
          <el-popconfirm
            v-has="'delete'"
            title="确定删除吗？"
            @confirm="handleDelete(row)"
          >
            <el-button slot="reference" class="ml10" type="text">
              <span class="colorRed">删除</span>
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </PageTable>
    <el-drawer
      size="50%"
      :wrapperClosable="false"
      :title="operationType | operationTypeDesc"
      :visible.sync="visible"
    >
      <Detail
        :operationType="operationType"
        :detailData="detailData"
        :areaList="areaList"
        @submit="handleSubmit"
        @cancel="visible = false"
      />
    </el-drawer>
  </div>
</template>
<script>
import {
  sceneTypeList,
  sellPositionList,
  MAXSIZE,
  settlementTypeList,
  gradeTypeList,
} from "@/common/constant/constant.js";
import Detail from "./Detail.vue";
import ExportTable from "@/components/ExportTable.vue";
import PageTable from "@/components/PageTableSort";
import {
  getPriceThresholdList,
  deletePriceThreshold,
  insertPriceThreshold,
  editPriceThreshold,
} from "./indexServe";
import { countriesPromotionList } from "@/common/js/api";
export default {
  name: "priceThreshold",
  components: { Detail, ExportTable, PageTable },
  mounted() {
    this.init();
    this.getAreaList();
  },
  data() {
    return {
      sceneTypeList,
      sellPositionList,
      settlementTypeList,
      gradeTypeList,
      gradeTypeList1: [],
      areaList: [],
      searchData: {
        sellPosition: "",
        iso2: "",
      },
      tableData: {
        list: [],
      },
      columns: [
        {
          slot: "sellPosition",
          label: "场景类型",
        },
        {
          slot: "counList",
          label: "国家",
        },
        {
          slot: "settlementType",
          label: "出价方式",
        },
        {
          key: "amountThreshold",
          label: "金额阈值",
        },
        {
          key: "createTime",
          label: "提交时间",
        },
        {
          slot: "status",
          label: "状态",
        },
      ],
      visible: false, //控制drawer的显示和隐藏
      operationType: "", // 记录当前操作模式新建-create、编辑-edit
      detailData: {},
      statusList: [
        { key: 1, value: "生效", tag: "success" },
        { key: 2, value: "失效", tag: "danger" },
      ],
    };
  },
  methods: {
    // 事件监听
    handleChangePage({ page, size }) {
      this.getTableList({ page, size });
    },
    handleChangeSceneType() {
      // 清空已选的推荐版位
      // this.searchData.sellPosition = "";
    },
    handleOperate(operationType, row) {
      if (["create", "edit"].includes(operationType)) {
        this.visible = true;
        if (operationType == "cerate") {
          this.detailData = {};
        } else {
          this.detailData = { ...row };
        }
      }
      this.operationType = operationType;
    },
    countChange(arr) {
      if (arr.length <= 0) {
        return "";
      } else {
        let newArr = arr.map((item) => {
          return item.name;
        });
        return newArr.join();
      }
    },
    handleDelete(row) {
      deletePriceThreshold({ id: row.id }).then((res) => {
        if (res && res.code === 0) {
          this.getTableList({
            page: this.tableData.page,
            size: this.tableData.size,
          }); // 删除成功后刷新列表
          this.$message.success("操作成功");
        }
      });
    },
    handleSearch() {
      this.getTableList({ page: 1 });
    },
    handleSubmit(formData) {
      const fcn =
        this.operationType === "edit"
          ? editPriceThreshold
          : insertPriceThreshold; // 编辑和新建分别调用不同接口
      fcn(formData).then((res) => {
        if (res && res.code === 0) {
          this.visible = false;
          this.getTableList({
            page: this.tableData.page,
            size: this.tableData.size,
          }); // 成功后刷新列表
          this.$message.success("操作成功");
        }
      });
    },

    // 后端请求
    // 获取列表
    async getTableList(params, isDownload) {
      const _params = {
        page: 1,
        size: 10,
        ...this.searchData,
        ...params,
      };
      if (!_params.sellPosition) {
        _params.sellPosition = undefined;
      }
      const res = await getPriceThresholdList(_params);
      if (res) {
        // 下载时，不需要更新到table
        if (!isDownload) {
          this.tableData = res.data;
        }
        return res.data;
      }
    },
    // 其他
    init() {
      this.getTableList();
    },
    async getDownloadList(callback) {
      let gradeTypeList1 = this.gradeTypeList;
      gradeTypeList1.unshift({ key: 0, value: "全部" });
      const res = await this.getTableList({ page: 1, size: MAXSIZE }, true);
      if (res) {
        const headerDisplay = {};
        this.columns.forEach((item) => {
          const key = item.key || item.slot;
          if (key !== "action") {
            headerDisplay[key] = item.label;
          }
        });
        // 处理导出的数据
        const list = res.list.map((i) => ({
          ...i,
          sellPosition: this.$options.filters["typeDesc"](
            i.sellPosition,
            this.sceneTypeList
          ),
          // sellPosition: this.$options.filters["typeDesc"](
          //   i.sellPosition,
          //   this.sellPositionList
          // ),
          // settlementType: this.$options.filters["typeDesc"](
          //   i.settlementType,
          //   this.sellPositionList
          // ),
          settlementType: "CPD",
          counList: this.countChange(i.counList),
          status: this.$options.filters["typeDesc"](i.status, this.statusList),
        }));
        callback({
          fileName: "出价阈值",
          headerDisplay,
          list,
        });
      }
    },
    async getAreaList() {
      const res = await countriesPromotionList({ page: 1, size: 3000 });
      let list = [];
      if (res && res.code == 0) {
        list = res.data.list || [];
        this.areaList = list;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.list-area {
  position: relative;
  .page-foot {
    display: inline-block;
    position: absolute;
    margin-top: -30px;
    div {
      margin-right: 5px;
    }
  }
}
</style>
