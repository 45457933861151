import request from "@/config/serve.js";

// 查询
export function getPriceThresholdList(data) {
  return request("post", "/priceThreshold/list", data);
}
// 新增
export function insertPriceThreshold(data) {
  return request("post", "/priceThreshold/insert", data);
}
// 修改
export function editPriceThreshold(data) {
  return request("post", "/priceThreshold/edit", data);
}
//删除
export function deletePriceThreshold(data) {
  return request("get", "/priceThreshold/delete", data);
}
